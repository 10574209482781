@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Trend-Sans';
    src: url('./Assets/Trend-Sans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lastica';
    src: url('./Assets/Lastica.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

